<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.vietnam.header') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
            <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.vietnam.header') }) }}</p>
          </div>
          <div class="form_main">
            <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, zotaForm.amount)" prop="amount">
                      <numeric-input
                        v-model="zotaForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'VND' }) }}
                      <span> {{ rate }}</span>
                    </p>
                    <p>
                      VND: <span>{{ getRate(rateChange) }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="zotaForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo circle bankVumSecondary"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import vietnamMixin from '@/mixins/page/deposit/vietnam';
import { apiVietnam_bankwire_payment } from '@/resource';

export default {
  name: 'vietnam_zotapay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin, vietnamMixin],
  data() {
    return {
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst12'),
        this.$t('deposit.reminder.inst13')
      ],
      showNote: false
    };
  },
  methods: {
    submitDeposit() {
      return apiVietnam_bankwire_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          depositAmount: this.getRate(this.rateChange),
          rate: this.rate
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
